.navContent {
   margin-right: 10%;
   transition: 0.3s;
}

.navbar-light .navbar-nav .nav-link {
   color: theme-color('textColor') !important;
}

.fixed-top {
   top: 50px;
   height: 127px;
}

.navbar-light .navbar-nav .nav-link {
   color: theme-color('textColor');
   margin-right: 30px;
   display: flex;
   justify-content: center;
   align-items: center;

   transition: 0.3s;
   font-family: "league-gothic";
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 22px;

   text-align: center;
   text-transform: uppercase;
}

#navLinksWhite .nav-link {
   color: #fff !important;
}

#navLinksBlack .nav-link {
   color: #fff !important;
}

.navbar-light .navbar-nav .nav-link {
   color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover {
   color: theme-color('primary') !important;
}

.nav-color-top {
   background-color: transparent;
   transition: 1s;
}

.nav-color-scroll {
   background-color: #000 !important;
}

.bold {
   font-weight: 700;
}

.brandName {
   color: theme-color('textColor') !important;
   position: relative;
   left: 10%;
   transition: 0.3s;
}

.brandNameWhite {
   color: #fff !important;
   position: relative;
   left: 10%;
   transition: 0.3s;
   height: 100%;
}

#navbar {
   transition: top 0.3s, 0.5s;
}

.navbar--hidden {
   top: -50px;
}



.navbar-light .navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.8)' stroke-width='4' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-collapse {
   // position: absolute;
   right: 86px;
}

.navBrandLogo {
   width: 100%;
   height: auto;
   position: relative;
   top: 30px;
   transition: 0.3s;
}

//TOGGLER
.navbar-toggler {
   border: none;
   font-size: 2em;
}

/* SQUARE EFFECT */

.curseLink {
   width: 162px;
   height: 50px;
   // border: 0;
   display: flex;
   justify-content: center;
   align-items: center;
}


// .curseLink::before,
// .curseLink::after {
//    box-sizing: inherit;
//    content: '';
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    border: 2px solid transparent;
//    width: 0;
//    height: 0;
// }

// .curseLink::before {
//    bottom: 0;
//    left: 0;
// }

// .curseLink::after {
//    top: 0;
//    right: 0;
// }

// .curseLink:hover {
//    color: theme-color('primary');
//    background-color: rgba(0, 0, 0, 0.199);
// }

// .curseLink:hover::before,
// .curseLink:hover::after {
//    width: 100%;
//    height: 100%;
// }

// .curseLink:hover::before {
//    border-bottom-color: theme-color('primary');
//    border-right-color: theme-color('primary');
//    -webkit-transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
//    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
// }

// .curseLink:hover::after {
//    border-top-color: theme-color('primary');
//    border-left-color: theme-color('primary');
//    -webkit-transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
//       height 0.25s ease-out 0.75s;
//    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
//       height 0.25s ease-out 0.75s;
// }

/* DESKTOP STYLE */

@media (max-width: 1400px) {
   .curseLink {
      right: 50px !important;
   }

   .brandName {
      left: 5%;
   }

   .brandNameWhite {
      left: 5%;
   }

   .navContent {
      margin-right: 0;
   }
}

@media (min-width: 991px) {

   /* UNDERLINE EFFECT */
   .justify-content-center {
      margin: 0 auto;
   }

   .linksNav {
      position: relative;
      transition: 0.5s;
   }

   .linksNav::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 3px;
      top: 100%;
      left: 0;
      background: theme-color('primary');
      transition: transform 0.5s;
      transform: scaleX(0);
      transform-origin: right;
   }

   .linksNav:hover::after {
      transform: scaleX(1);
      transform-origin: left;
   }

   .linksNav:focus::after {
      transform: scaleX(1);
   }
}

/* RESPONSIVE STYLE */

@media (max-width: 991px) {
   #navbar {
      height: 170px;
      padding-left: 0;
      padding-right: 0;
   }

   #containerNavToggle {
      height: 100% !important;
   }




   .navBrandLogo {
      height: 100px;
      width: 100%;
      top: 30px;
   }

   .nav-color {
      background: rgba(0, 0, 0, 0.747);
   }

   // .fixed-top {
   //    height: 70px;
   // }

   .navbar-light .navbar-nav .nav-link {
      text-align: center;
   }

   .nav-link:hover {
      background-color: rgba(0, 0, 0, 0.541);
      color: theme-color('primary');
   }

   .navbar-light .navbar-nav .nav-link {
      margin-right: 0;
   }

   .navbar-collapse {
      position: initial;
      right: 0;
   }

   .brandName,
   .brandNameWhite {
      position: absolute;
      left: 20px;
      top: 0;
   }

   .custom-toggler .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.8)' stroke-width='4' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
   }

   .custom-toggler.navbar-toggler {
      border-color: rgb(255, 102, 203);
   }

   .navbar-toggler {
      margin-right: 20px;
   }

   .mobileButton {
      position: absolute;
      right: 100px;
      top: 12.5px;
   }

   .curseLink {
      width: 120px;
      height: 40px;
      right: 0 !important;
   }

   .brandNameWhite svg {
      width: 150px;
      transition: 0.5s;
   }

   .navbar-collapse {
      background-color: #000;
   }
}

@media (max-width: 420px) {
   .brandNameWhite svg {
      width: 100px;
   }
}