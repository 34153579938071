body {
  padding-right: 0 !important;
}

.suscribeEmail label {
  display: block;
  text-align: center;
  line-height: 150%;
  font-size: 0.85em;
}

.modal-open .modal {
  background: rgba(0, 0, 0, 0.4);
}

.mailInput {
  color: #000 !important;
}

.error {
  color: red !important;
  font-size: 0.7em;
}

.modal-content {
  width: fit-content !important;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0) !important;
  transform: translate(0) !important;
  display: flex;
  justify-content: center;
}

.modal-body .my-5 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.popUpTitle {
  color: #000;
  font-size: 1.2em;
  /* width: 90%; */
  /* margin: 10px auto 20px auto; */
}

.popUpSubTitle {
  font-size: 1em;
  /* width: 90%; */
  margin: 0 auto;
}

.popUpLogo {
  height: 30px;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .modal {
    display: flex !important;
  }

  .modal-content {
    width: 90% !important;
    display: flex;
    align-items: center;
    height: fit-content;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    margin: 0 auto !important;
  }

  ._form_138 {
    width: fit-content !important;
    margin-top: 0 !important;
  }

  .modal-body {
    display: flex;
  }

  .modal-body .container h1 {
    margin-bottom: 0 !important;
  }

  .popUpTitle {
    font-size: 1em;
  }

  .popUpSubTitle {
    font-size: 0.8em;
  }

  .modalContainer .modal-header {
    width: 100%;
    height: auto;
  }

  .modal-content .modal-header button {
    position: relative;
    right: 10px;
    top: 0;
    outline: 0;
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }
}

@media (max-width: 500px) {
  .modal-body h1 {
    font-size: 1.3em;
  }

  ._form_120 {
    padding: 0 !important;
    padding-top: 10px !important;
  }
}