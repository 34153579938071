.testimonialsSectionRow {
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.carousel {
    background: #eee;
  }
  
  .carousel-cell {
    width: 33%;
    height: 534px;
    margin-right: 10px;
    background: theme-color("primary");
    border-radius: 5px;
    counter-increment: carousel-cell;
  }

  
  /* cell number */
  .carousel-cell:before {
    display: block;
    text-align: center;
    line-height: 200px;
    font-size: 80px;
    color: white;
  }
  
  .imgCarousel {
    background-repeat: no-repeat;
    // background-position: center;
    background-size: cover;
  }
  
  .flickity-page-dots .dot {
    // border-radius: 0;
    background-color: theme-color('primary');
  }
  
  /* position outside */
  @media (min-width: 992px) {
    .flickity-prev-next-button.previous {
      left: -60px;
    }
    
    .flickity-prev-next-button.next {
      right: -60px;
    }
    
  }
  .flickity-prev-next-button .flickity-button-icon {
    background: none;
    fill: theme-color("primary");
  }
  
  .flickity-button {
    background: none;
  }

//   .flickityImages {
//     border-radius: 50px;
//   }

  .px-1 {
    padding-left: 4.25rem !important;
  }

//   

.playContainer {
    transition: 0.3s;
  position: absolute;
  width: 100%;
  height: 100%;
    background-color: rgba(0, 0, 0, 0.473);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .carouselImgsContainer {
      position: relative;
  }
  .playIcon {
      transition: 0.3s;
    font-size: 2em;
    color: #fff;
  }
  .testimoniosVid1:hover .playIcon, .testimoniosVid2:hover .playIcon {
    color: theme-color('primary');
   }
   .carouselImgs:hover .playIcon {
    color: theme-color('primary');
   }
   .carouselImgs:hover .playContainer {
    background-color: rgba(0, 0, 0, 0.0);
   }
   .gcontainer {
    cursor: auto;
   }
   .ginner-container {
     max-width: 760px !important;
   }


   .testimoniosSectionTitle {
    color: #fff;
    margin: 0 auto;
    margin-bottom: 2vw;
 }
 



@media (max-width: 991px) {  
    .testimonialsText {
        padding-left: 0;
        padding-right: 0;
    }
    .carouselImgs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        display: flex;
        justify-content: center;
      }
}
